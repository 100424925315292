import Request from '@/utils/request';

//获取手机验证码
export function sentSms(data) {
  return Request('post', '/apd/salesmini/sendVcode?mobile=' + data)
}


//扫码修改看房信息
export function addAndVisit(data) {
  return Request('post', '/apd/salesmini/addAndVisit' , data)
}


//扫码修改看房信息
export function customerByProject(data) {
  return Request('post', `/apd/${data.spId}/partner/customer/customerByProject` , data)
}