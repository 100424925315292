// 线索状态
export const clueStatusOpt = [{
    value: "1",
    label: "潜在",
    codeName: "potentialCustomer",
    imgSrc: require("../assets/images/clue/qianzai.png"),
    count: "--",
  },
  {
    value: "2",
    label: "看房",
    codeName: "interestedCustomer",
    imgSrc: require("../assets/images/clue/kanfang.png"),
    count: "--",
  },
  {
    value: "4",
    label: "无效",
    codeName: "lossCustomer",
    imgSrc: require("../assets/images/clue/wuxiao.png"),
    count: "--",
  },
  {
    value: "3",
    label: "签约",
    codeName: "signedCustomer",
    imgSrc: require("../assets/images/clue/qianyue.png"),
    count: "--",
  },
  {
    value: "",
    label: "全部",
    codeName: "sumCount",
    imgSrc: require("../assets/images/clue/quanbu.png"),
    count: "--",
  },
]
export const clueStatusObj = {
  1: "潜在客户",
  2: "看房客户",
  3: "签约客户",
  4: "无效客户",
  5: "历史客户",
}
export const clueStatusColorObj = {
  1: "#1FDC73",
  2: "#FFB800",
  3: "#1E9FFF",
  4: "#F22828",
  5: "#909399",
}

// 时间范围
export const dateToDateOpt = {
  1: "昨天",
  2: "今天",
  3: "本周",
  4: "本月",
  5: "本年",
  6: "全部"
}

export const turnoverOddsObj = {
  1: "高",
  2: "中",
  3: "低"
}

// 面积
export const areaList = [{
    value: "1",
    text: "0-100㎡",
  },
  {
    value: "2",
    text: "100-200㎡",
  },
  {
    value: "3",
    text: "200-300㎡",
  },
  {
    value: "4",
    text: "300-500㎡",
  },
  {
    value: "5",
    text: "500-1000㎡",
  },
  {
    value: "6",
    text: "1000㎡以上",
  }
]